import { Helmet } from 'react-helmet';
import Layout from '../../components/layout';
import React from 'react';
import Thankyou from '../../components/webinar/thankyou';
import { WebinarCookieManagementContextProvider } from '../../context/webinarCookiesManagementContext';
import { WebinarFormValidationContextProvider } from '../../context/webinarFormValidationContext';

const index = () => {
  return (
    <Layout header footer stickyFooter={false}>
      <Helmet>
        <title>Thank You</title>
        <meta name='og:description' content='Thank you for Attending!' />
        <meta name='description' content='Thank you for Attending!' />
        <meta name='og:title' content='Thank You' />
        <meta name='title' content='Thank You' />
      </Helmet>
      <WebinarFormValidationContextProvider>
        <WebinarCookieManagementContextProvider>
          <Thankyou
            content={{
              header: 'Thank you for Attending!',
              description: [
                <>
                  Thank you for taking the time to watch our webinar! We hope
                  you gained valuable insights about the Medicare program and
                  were able to find answers to all your questions. If you found
                  this webinar helpful, feel free to check out our{' '}
                  <a
                    href='https://www.medicarefaq.com/?utm_source=webinar&utm_medium=thankyou&utm_campaign=post_webinar_thankyou&utm_content=blog_link'
                    className='text-purple'
                    style={{
                      textDecoration: 'underline',
                    }}
                  >
                    blog
                  </a>{' '}
                  where we post the latest news to keep you up-to-date on all
                  things Medicare.
                </>,
                'We appreciate your participation! Don’t forget to stay connected for education, resources, and future Medicare updates. We are available to help you with your Medicare needs anytime you need us!',
              ],
            }}
            isThankYou={true}
          />
        </WebinarCookieManagementContextProvider>
      </WebinarFormValidationContextProvider>
    </Layout>
  );
};

export default index;
